<template>
  <div class="overview" v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.POST_ORIGINATION_LOAN_DETAILS.value, path: $route.path}">
    <div class="head-content round">
      <v-card
        dark
        flat
        color="transparent"
        class="amount-panel"
      >
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <h3 class="loan-no">{{ layouts.data.repaymentLoan.loanNo }}</h3>
              <div class="loan-amount">
                <div class="field-label">Total</div>
                <div class="field-value">{{ assist.currency.format(layouts.data.repaymentLoan.totalPrincipal) }}</div>
              </div>
              <v-btn
                outlined
                @click="onClickPaymentPlan"
              >
                Payment Plan
                <v-icon
                  right
                  dark
                >
                  navigate_next
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <div class="progress-panel">
                <van-circle
                  layer-color="#353535"
                  :color="color"
                  stroke-width="80"
                  size="170"
                  :rate="(layouts.data.repaymentLoan.unpaidPrincipal / layouts.data.repaymentLoan.totalPrincipal) * 100"
                  v-model="currentRate"
                >
                  <p class="circle-top field-label f-s-12">Unpaid Principal</p>
                  <p class="circle-middle field-value f-s-24">{{ assist.currency.format(layouts.data.repaymentLoan.unpaidPrincipal) }}</p>
                </van-circle>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <div class="main-content">
      <div v-min-height="400">
        <v-list
        class="property-panel"
      >
        <template v-for="(property, i) in layouts.settings.properties">
          <v-list-item
            :key="`PROPERTY-${ i }`"
          >
            <v-list-item-avatar
              class="rounded-lg rounded-tl rounded-br"
            >
              <v-img
                :src="property.icon"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="field-label" v-text="property.label"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="!property.multiples">
              {{ renderValue(property.key) }}
            </v-list-item-action>
          </v-list-item>
          <div
            :key="`PROPERTY-MULTIPLES-${ i }`"
            v-if="property.multiples"
            class="property-multiple-lines"
          >
            <template
              v-for="(line, j) in property.value"
            >
              <div
                :key="`PROPERTY-MULTIPLES-LINE-${ j }`"
              >
                {{ line }}
              </div>
            </template>
          </div>
        </template>
      </v-list>
      </div>
    </div>
    <!-- preview payment plan -->
    <preview-payment-schedule
      v-model="layouts.data.showPreview"
      :paymentInstallment="layouts.data.repaymentLoan.installments"
      title="Payment Schedule"
      @confirm="layouts.data.showPreview = false"
    >
      <div slot="title">Payment Plan</div>
    </preview-payment-schedule>
  </div>
</template>

<script>
import { Circle } from 'vant/es'
import 'vant/es/circle/style'
import { Currency, Tool, DataType } from '@/assets/js/util'
import PreviewPaymentSchedule from '@/components/disbursement/PreviewPaymentSchedule'

export default {
  name: 'LoanDetails',
  components: {
    [Circle.name]: Circle,
    PreviewPaymentSchedule
  },
  data () {
    return {
      assist: {
        currency: new Currency(),
        dataType: DataType,
        tool: new Tool()
      },
      repaymentLoan: {
        unpaidAmount: 0
      },
      currentRate: 0,
      color: {
        '0%': '#ffffff',
        '100%': 'var(--theme_secondary)'
      },
      layouts: {
        settings: {
          properties: [
            {
              icon: require('@/assets/images/repayment/payment-count.png'),
              label: '# of Installments',
              key: 'debitCount'
            },
            {
              icon: require('@/assets/images/repayment/regular-amount.png'),
              label: '$ of Regular Installment',
              key: 'regularAmount'
            },
            {
              icon: require('@/assets/images/repayment/first-date.png'),
              label: 'First Due Date',
              key: 'firstDebitDate'
            },
            {
              icon: require('@/assets/images/repayment/maturity-date.png'),
              label: 'Maturity Date',
              key: 'lastDebitDate'
            },
            {
              icon: require('@/assets/images/repayment/apr.png'),
              label: 'APR',
              key: 'apr'
            }
          ]
        },
        data: {
          repaymentLoan: {
            loanId: null,
            loanNo: '',
            totalPrincipal: 0,
            installments: [],
            apr: '',
            debitCount: '',
            regularAmount: '',
            firstDebitDate: '',
            lastDebitDate: '',
            unpaidPrincipal: ''
          },
          showPreview: false
        }
      }
    }
  },
  watch: {
    '$store.getters.getRepaymentLoanInfo': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.repaymentLoan = Object.assign(val)
        }
      }
    }
  },
  mounted () {
    const loan = this.$store.getters.getRepaymentLoanInfo
    this.layouts.data.repaymentLoan = Object.assign(loan)
  },
  methods: {
    onClickPaymentPlan () {
      this.layouts.data.showPreview = true
    },
    renderValue (key) {
      if (key === 'apr') {
        return this.assist.tool.formatPercent(this.layouts.data.repaymentLoan[key])
      } else if (key === 'regularAmount') {
        return this.assist.currency.format(this.layouts.data.repaymentLoan[key])
      } else {
        return this.layouts.data.repaymentLoan[key]
      }
    }
  }
}
</script>

<style lang="sass" scoped>
  .overview
    & .field-label
      opacity: 0.6
    & .field-value
      opacity: 0.8
    & .amount-panel
      padding-bottom: 16px
      & .loan-no
        white-space: nowrap
        line-height: 1.5
      & .loan-amount
        padding: 10px 0
      & .field-value
        font-size: 32px
        font-weight: 700
        line-height: 1.5
        color: var(--theme_secondary)
        white-space: nowrap
    & .progress-panel
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      height: 100%
    & .property-panel
      width: 100%
      background: transparent
      & .v-list-item
        padding: 0
      & .field-label
        font-size: 14px
      & .field-value
        font-size: 14px
      & .property-multiple-lines
        padding-left: 56px
  .circle-top
    position: absolute
    top: 36%
    left: 0
    width: 100%
  .circle-middle
    position: absolute
    top: 48%
    left: 0
    width: 100%
</style>
